import _identity2 from "lodash/fp/identity";
import _pickBy from "lodash/fp/pickBy";
import _pick from "lodash/pick";
import _isEqual from "lodash/isEqual";
import _snakeCase from "lodash/snakeCase";
import _identity from "lodash/identity";
import _camelCase from "lodash/camelCase";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly)
        symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; });
    keys.push.apply(keys, symbols);
} return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); });
    }
    else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    }
    else {
        ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); });
    }
} return target; }
function _defineProperty(obj, key, value) { if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
}
else {
    obj[key] = value;
} return obj; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr)))
    return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);
        if (i && _arr.length === i)
            break;
    }
}
catch (err) {
    _d = true;
    _e = err;
}
finally {
    try {
        if (!_n && _i["return"] != null)
            _i["return"]();
    }
    finally {
        if (_d)
            throw _e;
    }
} return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr))
    return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter))
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
} return arr2; }
import queryString from 'query-string';
import { formValueSelector, getFormValues, autofill as autofillForm } from 'redux-form';
import { getSavedDisplayEmployeeLimit, pipe } from '../components/utils';
export var formName = 'userSearchTop';
export var selector = formValueSelector(formName);
export var getValues = getFormValues(formName);
export var autofill = autofillForm.bind(null, formName);
export var defaultQuery = { role_type: 'all', _page: '1', sort_type: 'staff_code__asc' };
export var convertQueryToForm = function convertQueryToForm(query) { return Object.assign.apply(Object, [{ sortType: "".concat(query.sort, "__").concat(query.order) }].concat(_toConsumableArray(Object.entries(query).map(function (_ref) { var _ref2 = _slicedToArray(_ref, 2), k = _ref2[0], v = _ref2[1]; return [k[0] === '_' ? "_".concat(_camelCase(k)) : _camelCase(k), v]; }).map(function (_ref3) { var _ref4 = _slicedToArray(_ref3, 2), k = _ref4[0], v = _ref4[1]; return convertQueryToFormField(k, v); }).filter(_identity)))); };
var convertQueryToFormField = function convertQueryToFormField(fieldName, value) {
    var _a;
    switch (fieldName) {
        case '_page': return { page: value };
        case 'sort':
        case 'order': return undefined;
        default: return _a = {}, _a[fieldName] = value, _a;
    }
};
export var convertFormToQueryString = function convertFormToQueryString(data) { return queryString.stringify(convertFormToQuery(data), { arrayFormat: 'bracket' }); };
var convertFormToQuery = function convertFormToQuery(data) { if (data === undefined)
    return {}; return Object.assign.apply(Object, [{}].concat(_toConsumableArray(Object.entries(data).map(function (_ref5) { var _ref6 = _slicedToArray(_ref5, 2), k = _ref6[0], v = _ref6[1]; return [k[0] === '_' ? "_".concat(_snakeCase(k)) : _snakeCase(k), v]; }).map(function (_ref7) { var _ref8 = _slicedToArray(_ref7, 2), k = _ref8[0], v = _ref8[1]; return convertFormToQueryField(k, v); }).filter(_identity)))); };
var convertFormToQueryField = function convertFormToQueryField(fieldName, value) {
    var _a;
    switch (fieldName) {
        case 'limit': return null;
        case 'page': return { _page: value };
        case 'sortType': {
            var _value$split = value.split('__'), _value$split2 = _slicedToArray(_value$split, 2), sort = _value$split2[0], order = _value$split2[1];
            return { order: order, sort: sort };
        }
        default: return _a = {}, _a[fieldName] = value, _a;
    }
};
export var getCurrentQueryFromLocation = function getCurrentQueryFromLocation(location, user) { return _objectSpread(_objectSpread(_objectSpread({}, defaultQuery), queryString.parse(location.search, { arrayFormat: 'bracket' })), {}, { limit: getSavedDisplayEmployeeLimit(location.pathname, user) }); };
export var hasConditions = function hasConditions(formValues) { return !_isEqual(pipe(convertFormToQuery(formValues), _pickBy(_identity2)), defaultQuery); };
export var convertFormToGraphQl = function convertFormToGraphQl(formValues) { var _split = (formValues.sortType || convertQueryToForm(defaultQuery).sortType).split('__'), _split2 = _slicedToArray(_split, 2), sortColumn = _split2[0], sortOrder = _split2[1]; return { page: +formValues.page || 1, limit: +formValues.limit || 100, search: _objectSpread(_objectSpread(_objectSpread({}, formValues.roleType !== 'all' && _pick(formValues, 'roleType')), _pick(formValues, 'q')), {}, { sortColumn: sortColumn, sortOrder: sortOrder }) }; };
